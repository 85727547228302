import { environment as env } from '@environments/environment';
import { Brand } from '@models/brands/brand.model';
import { EventCampaignCategory } from '@models/campaigns/event-campaign-category.enum';
import { EventCampaignPartyStatus } from '@models/campaigns/event-campaign-party-status.enum';
import { EventCampaignStatus } from '@models/campaigns/event-campaign-status.enum';
import { EventCampaignWorkingStatus } from '@models/campaigns/event-campaign-working-status.enum';
import { EventCampaign } from '@models/campaigns/event-campaign.model';
import { ClassData } from '@models/class-data';
import { EventTemplateWidgetStatus } from '@models/design-templates/event-template-widget-status.enum';
import { EventTemplate } from '@models/design-templates/event-template.model';
import { EventAttendeeQuestion } from '@models/event-attendance/event-attendee-question.model';
import { EventAttendeeStatus } from '@models/event-attendance/event-attendee-status.enum';
import { EventAttendee } from '@models/event-attendance/event-attendee.model';
import { EventRSVPConfirmationText } from '@models/event-attendance/event-rsvp-confirmation-text.model';
import { EventRSVPOption } from '@models/event-attendance/event-rsvp-option.model';
import { RSVPLayout } from '@models/event-attendance/rsvp-layout.enum';
import { RSVPOptionType } from '@models/event-attendance/rsvp-option-type.enum';
import { EventCategory } from '@models/event-categories/event-category.model';
import { EventHostRoleEnum } from '@models/events/event-host-role.enum';
import { EventWidget } from '@models/events/event-widget.enum';
import { LandingPageEvent } from '@models/events/landing-page-event.model';
import { RSVPFormAppearance } from '@models/events/rsvp-form-appearance.enum';
import { Address } from '@models/shared/address.model';
import { DateFormat } from '@models/shared/date-format.enum';
import { TicketPaymentOptions } from '@models/tickets/ticket-payment-options.model';
import { TicketPayoutSettings } from '@models/tickets/ticket-payout-settings.model';
import { TicketRefundPolicy } from '@models/tickets/ticket-refund-policy.model';
import { TicketSeller } from '@models/tickets/ticket-seller.model';
import { TimezoneOption } from '@models/timezone/timezone-option.model';
import { EmailSender } from '@models/users/email-sender.model';
import { UserRole } from '@models/users/user-role.enum';
import { User } from '@models/users/user.model';
import { Helpers } from '@utils/helpers';
import { NumberUtils } from '@utils/number-utils';
import moment, { Moment } from 'moment-timezone';
import { EventAccess } from './event-access.enum';
import { EventFrequency } from './event-frequency.enum';
import { EventHostStatus } from './event-host-status.enum';
import { EventHost } from './event-host.model';
import { EventPayment } from './event-payment.model';
import { EventStatus } from './event-status.enum';
import { EventTargetGroup } from './event-target-group.model';
import { EventTicket } from './event-ticket.model';
import { EventType } from './event-type.enum';
import { CommunityEvent } from '@models/communities/community-event.model';
import { DateUtils } from '@utils/date-utils';
import { StringUtils } from '@utils/string-utils';

export class Event {
  id: number;
  createdAt?: Date;

  type: EventType;

  uuid: string;
  uri: string;

  categoryId: number;
  category?: EventCategory;

  eventTemplateId: number;
  eventTemplate?: EventTemplate;

  brandId?: number;
  brand?: Brand;

  hostUserId: number;
  hostUser?: User;
  // used by admin
  hostUserFull?: User;
  hostTeamId?: number;

  hosts?: EventHost[];

  eventAddressId: number;
  eventAddress?: Address;

  paymentId?: number;
  payment?: EventPayment;

  targetGroups?: EventTargetGroup[];
  attendees?: EventAttendee[];

  rsvpOptions?: EventRSVPOption[];
  rsvpConfirmationTexts?: EventRSVPConfirmationText[];

  status: EventStatus;
  access: EventAccess;

  activeFromDate?: Date;
  accessCode?: string;

  name: string;
  description: string;
  subHeader?: string;
  actionSectionTitle?: string;

  mainPictureUrl?: string;
  customPictureUrl?: string;
  tags: string;

  frequency: EventFrequency;

  tickets?: EventTicket[];

  //attendeeQuestions?: EventAttendeeQuestion[];

  eventCampaignId?: number;
  eventCampaign?: EventCampaign;

  startDate?: Date;
  endDate?: Date;
  timeZone: string;

  maxCapacity?: number;
  maxTicketCapacity?: number;
  waitlistEnabled?: boolean;

  plusOnesLimit?: number;
  /*  plusOnesAdditionalData: boolean;
  showPlusOnesFirstName: boolean;
  showPlusOnesLastName: boolean;
  showPlusOnesEmail: boolean;
  showPlusOnesPhone: boolean; */

  showGuestList: boolean;
  showGuestCount: boolean;
  showFeedTimestamps: boolean;
  canAttendeesAddPhotos: boolean;
  canAddPhotosViaHashtag: boolean;
  canAttendeeInviteMutuals: boolean;

  rsvpEnabled: boolean;
  rsvpDisableDate?: Date;
  requireAttendeeApproval: boolean;
  /*  requireGroupRsvp: boolean;
  rsvpWithoutEmail: boolean;
  oneButtonSignUp: boolean; */

  sendEmailNotifications: boolean;
  sendSmsNotifications: boolean;
  sendWhatsAppNotifications: boolean;

  // used for template preview
  isPreviewEvent?: boolean;
  hiddenInLinkBio?: boolean;
  highlightedInLinkBio?: boolean;

  dateFormat?: DateFormat;

  // ai
  aiStylePaletteId?: number;
  aiCustomStyle?: string;
  aiToneOfVoice?: string;
  aiMultipleOrganizers?: boolean;

  emailSenderId?: number;
  emailSender?: EmailSender;

  isPro: boolean;

  landingPageEvents?: LandingPageEvent[];

  numGuests?: number;
  // auto campaign party status
  campaignPartyStatus?: EventCampaignPartyStatus;

  //ticketing
  ticketSellerId?: number;
  ticketSeller?: TicketSeller;
  ticketPayoutSettingsId?: number;
  ticketPayoutSettings?: TicketPayoutSettings;
  ticketPaymentOptionsId?: number;
  ticketPaymentOptions?: TicketPaymentOptions;
  ticketRefundPolicyId?: number;
  ticketRefundPolicy?: TicketRefundPolicy;

  community?: CommunityEvent;

  // integrations
  isHubspotEnabled?: boolean;

  language?: string;

  constructor(json: ClassData<Event>) {
    this.id = +json.id;
    if (json.createdAt) {
      this.createdAt = new Date(json.createdAt);
    }

    this.type = json.type;

    this.uuid = json.uuid;
    this.uri = json.uri;

    this.categoryId = +json.categoryId;
    if (json.category) {
      this.category = new EventCategory(json.category);
    }

    this.eventTemplateId = +json.eventTemplateId;
    if (json.eventTemplate) {
      this.eventTemplate = new EventTemplate(json.eventTemplate);
    }

    if (json.brandId !== undefined) {
      this.brandId = +json.brandId;
    }
    if (json.brand) {
      this.brand = new Brand(json.brand);
    }

    this.hostUserId = +json.hostUserId;
    if (json.hostUser) {
      this.hostUser = new User(json.hostUser);
    }
    if (json.hostUserFull) {
      this.hostUserFull = new User(json.hostUserFull);
    }
    if (json.hostTeamId !== undefined) {
      this.hostTeamId = +json.hostTeamId;
    }

    if (json.hosts) {
      this.hosts = this.mapEventHosts(json.hosts);
    }

    this.eventAddressId = +json.eventAddressId;
    if (json.eventAddress) {
      this.eventAddress = new Address(json.eventAddress);
    }

    if (json.paymentId !== undefined) {
      this.paymentId = +json.paymentId;
    }
    if (json.payment) {
      this.payment = new EventPayment(json.payment);
    }

    if (json.targetGroups) {
      this.targetGroups = json.targetGroups;
    }

    if (json.attendees) {
      this.attendees = this.mapEventAttendees(json.attendees);
    }

    if (json.rsvpOptions) {
      this.rsvpOptions = this.mapEventRSVPOptions(json.rsvpOptions);
    }

    if (!this.rsvpOptions || this.rsvpOptions.length === 0) {
      this.rsvpOptions = this.setDefaultRSVPOptions();
    }

    if (json.rsvpConfirmationTexts) {
      this.rsvpConfirmationTexts = this.mapRsvpConfirmationTexts(
        json.rsvpConfirmationTexts,
      );
    }

    this.status = json.status;
    this.access = json.access;

    if (json.activeFromDate) {
      this.activeFromDate = new Date(json.activeFromDate);
    }
    this.accessCode = json.accessCode;

    this.name = json.name;
    this.description = json.description;
    this.subHeader = json.subHeader;
    this.actionSectionTitle = json.actionSectionTitle;

    this.mainPictureUrl = json.mainPictureUrl;
    this.customPictureUrl = json.customPictureUrl;
    this.tags = json.tags;

    this.frequency = json.frequency;

    if (json.tickets) {
      this.tickets = this.mapTickets(json.tickets);
    }

    /*     if (json.attendeeQuestions) {
      this.attendeeQuestions = this.mapAttendeeQuestions(
        json.attendeeQuestions,
      );
    } */

    if (json.eventCampaignId !== undefined) {
      this.eventCampaignId = +json.eventCampaignId;
    }
    if (json.eventCampaign) {
      this.eventCampaign = new EventCampaign(json.eventCampaign);
    }

    if (json.startDate) {
      this.startDate = new Date(json.startDate);
    }
    if (json.endDate) {
      this.endDate = new Date(json.endDate);
    }
    this.timeZone = json.timeZone;

    if (json.maxCapacity !== undefined) {
      this.maxCapacity = +json.maxCapacity;
    }
    this.maxTicketCapacity = json.maxTicketCapacity;
    if (json.plusOnesLimit !== undefined) {
      this.plusOnesLimit = +json.plusOnesLimit;
    }
    /* this.plusOnesAdditionalData = json.plusOnesAdditionalData;
    this.showPlusOnesFirstName = json.showPlusOnesFirstName;
    this.showPlusOnesLastName = json.showPlusOnesLastName;
    this.showPlusOnesEmail = json.showPlusOnesEmail;
    this.showPlusOnesPhone = json.showPlusOnesPhone; */

    this.showGuestList = json.showGuestList;
    this.showGuestCount = json.showGuestCount;
    this.showFeedTimestamps = json.showFeedTimestamps;
    this.canAttendeesAddPhotos = json.canAttendeesAddPhotos;
    this.canAddPhotosViaHashtag = json.canAddPhotosViaHashtag;
    this.canAttendeeInviteMutuals = json.canAttendeeInviteMutuals;
    this.waitlistEnabled = json.waitlistEnabled;

    this.rsvpEnabled = json.rsvpEnabled;
    if (json.rsvpDisableDate) {
      this.rsvpDisableDate = new Date(json.rsvpDisableDate);
    }
    this.requireAttendeeApproval = json.requireAttendeeApproval;
    /*  this.requireGroupRsvp = json.requireGroupRsvp;
    this.rsvpWithoutEmail = json.rsvpWithoutEmail;
    this.oneButtonSignUp = json.oneButtonSignUp; */

    this.sendEmailNotifications = json.sendEmailNotifications;
    this.sendSmsNotifications = json.sendSmsNotifications;
    this.sendWhatsAppNotifications = json.sendWhatsAppNotifications;

    this.isPreviewEvent = !!json.isPreviewEvent;
    this.hiddenInLinkBio = !!json.hiddenInLinkBio;
    this.highlightedInLinkBio = !!json.highlightedInLinkBio;

    this.dateFormat = json.dateFormat;

    // ai
    if (json.aiStylePaletteId) {
      this.aiStylePaletteId = json.aiStylePaletteId;
    }
    this.aiCustomStyle = json.aiCustomStyle;
    this.aiToneOfVoice = json.aiToneOfVoice;
    this.aiMultipleOrganizers = json.aiMultipleOrganizers;

    this.emailSenderId = json.emailSenderId;
    if (json.emailSender) {
      this.emailSender = new EmailSender(json.emailSender);
    }

    this.isPro = json.isPro;

    if (json.landingPageEvents) {
      this.landingPageEvents = this.mapLandingPageEvents(
        json.landingPageEvents,
      );
    }

    this.numGuests = json.numGuests;
    this.campaignPartyStatus = json.campaignPartyStatus;
    this.ticketSellerId = json.ticketSellerId;
    if (json.ticketSeller) {
      this.ticketSeller = new TicketSeller(json.ticketSeller);
    }
    this.ticketPayoutSettingsId = json.ticketPayoutSettingsId;
    if (json.ticketPayoutSettings) {
      this.ticketPayoutSettings = json.ticketPayoutSettings;
    }
    this.ticketRefundPolicyId = json.ticketRefundPolicyId;
    if (json.ticketRefundPolicy) {
      this.ticketRefundPolicy = json.ticketRefundPolicy;
    }
    this.ticketPaymentOptionsId = json.ticketPaymentOptionsId;
    if (json.ticketPaymentOptions) {
      this.ticketPaymentOptions = json.ticketPaymentOptions;
    }

    if (json.community) {
      this.community = json.community;
    }

    if (json.isHubspotEnabled) {
      this.isHubspotEnabled = json.isHubspotEnabled;
    }

    this.language = json.language;
  }

  getMainRsvpOptions() {
    return this.rsvpOptions?.filter((x) => x.type !== RSVPOptionType.MAYBE);
  }

  endsOnSameDate(): boolean {
    if (!this.endDate) {
      return false;
    }

    return moment(this.startDate).isSame(this.endDate, 'day');
  }

  hasApp(app: EventWidget) {
    if (this.eventTemplate?.widgets?.includes(app)) {
      return true;
    }
    return false;
  }

  hasAppFull(app: EventWidget) {
    if (this.eventTemplate?.widgetsFull?.map((x) => x.widget).includes(app)) {
      return this.eventTemplate?.widgetsFull?.find((x) => x.widget === app);
    }
    return undefined;
  }

  hasPublishedSellTickets() {
    const app = this.hasAppFull(EventWidget.SELL_TICKETS);
    if (app && app.status === EventTemplateWidgetStatus.PUBLISHED) {
      return true;
    }
    return false;
  }

  hasOneButtonSignUp(): boolean {
    return (
      this.type === EventType.SPORTPAGE ||
      this.eventTemplate?.rsvpSettings?.layout === RSVPLayout.ONE_BUTTON
    );
  }
  hasTwoButtonSignUp(): boolean {
    return this.eventTemplate?.rsvpSettings?.layout === RSVPLayout.TWO_BUTTONS;
  }

  hasAddress(): boolean {
    return (
      !!this.eventAddress &&
      (this.eventAddress.isFilled() || this.eventAddress.isPartiallyFilled())
    );
  }

  hasLatLng(): boolean {
    return !!this.eventAddress && this.eventAddress.hasLatLng();
  }

  checkIfCanSellTickets() {
    return (
      this.name.toLowerCase().startsWith('test ticketing') ||
      this.type === EventType.ADVANCED_ONE_COLUMN
    );
  }

  getSportPageLocationDisplay(htmlLineBreak?: boolean): string | undefined {
    if (this.eventAddress) {
      let name = '';
      let street = '';
      let streetNumber = '';

      if (this.eventAddress.name) {
        name = `(${this.eventAddress.name})`;
      }

      if (this.eventAddress.street) {
        if (this.eventAddress.streetNumber) {
          streetNumber = this.eventAddress.streetNumber;
        }
        if (streetNumber !== '') {
          street = `${this.eventAddress.street} ${streetNumber},`;
        } else {
          street = `${this.eventAddress.street},`;
        }
      }

      if (htmlLineBreak) {
        return `${street}<br/>${this.eventAddress.postalCode} ${this.eventAddress.locality} ${name}`;
      }
      return `${street} ${this.eventAddress.postalCode} ${this.eventAddress.locality} ${name}`;
    } else {
      return 'Die genaue Adresse der Location folgt';
    }
  }

  getStreet(): string {
    if (!this.eventAddress) {
      return '-';
    }

    return this.eventAddress.street;
  }
  getAddressDisplayName(): string {
    if (!this.eventAddress) {
      return '-';
    }
    return this.eventAddress.toStringWithName();
  }

  getAddressDisplayNamePrimary(): string {
    if (!this.eventAddress) {
      return '-';
    }
    return this.eventAddress.toDisplayForEventPrimary();
  }

  getAddressDisplayNameSecondary(): string {
    if (!this.eventAddress) {
      return '-';
    }
    return this.eventAddress.toDisplayForEventSecondary();
  }

  getAddressDisplayNameCommunity(): string {
    if (!this.eventAddress) {
      return '-';
    }
    return this.eventAddress.toDisplayForEventCommunityCard();
  }

  getGoogleMapsLink() {
    if (!this.eventAddress) {
      return '';
    }

    if (
      this.eventAddress.placeId &&
      this.eventAddress.placeId !== '' &&
      this.eventAddress.placeId.startsWith('Ch')
    ) {
      return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
        this.eventAddress.toStringWithName(),
      )}&query_place_id=${this.eventAddress.placeId}`;
    } else if (this.eventAddress.lat && this.eventAddress.lng) {
      return `https://www.google.com/maps/search/?api=1&query=${this.eventAddress.lat},${this.eventAddress.lng}`;
    } else {
      return '';
    }
  }

  getDisplayDate(): string {
    if (!this.startDate && !this.endDate) {
      return '-';
    }

    const startDate = moment(this.startDate).format('DD.MM.yyyy');
    const startTime = moment(this.startDate).format('HH:mm');
    const endTime = this.endDate ? moment(this.endDate).format('HH:mm') : '';

    let displayDate = `${startDate} ${startTime}`;

    if (this.endsOnSameDate()) {
      displayDate = `${displayDate} - ${endTime}`;
    } else if (this.endDate && !this.endsOnSameDate()) {
      const endDate = moment(this.endDate).format('DD.MM.yyyy');
      displayDate = `${displayDate} - ${endDate} ${endTime}`;
    }

    return displayDate;
  }

  getEventInfoDateDisplay(
    date: Date | undefined,
    currentLang: string | undefined,
    isSecondLine = false,
  ): string {
    if (!date) {
      return '';
    }
    let dateInfo =
      DateUtils.formatDateDay(date, currentLang) +
      ', ' +
      moment(date).format('DD') +
      '. ' +
      DateUtils.formatDateMonth(date, currentLang);

    if (this.endDate && !this.endsOnSameDate()) {
      dateInfo += ` • ${
        this.is12HourDateFormat()
          ? moment(date).format('h:mm a')
          : moment(date).format('HH:mm')
      }`;

      if (isSecondLine) {
        dateInfo += ' ' + DateUtils.getTimezoneDisplay(this.timeZone);
      }
    }

    return dateInfo;
  }

  getEventInfoDateLine1(currentLang: string | undefined): string {
    return this.getEventInfoDateDisplay(this.startDate, currentLang);
  }

  getEventInfoDateLine2(currentLang: string | undefined): string {
    if (!this.startDate) {
      return '';
    }

    let dateInfo = this.getStartTime();

    if (!this.endDate) {
      return dateInfo + ' ' + DateUtils.getTimezoneDisplay(this.timeZone);
    }
    if (this.endsOnSameDate()) {
      return `${dateInfo} - ${this.getEndTime()} ${DateUtils.getTimezoneDisplay(
        this.timeZone,
      )}`;
    } else if (this.endDate && !this.endsOnSameDate()) {
      return this.getEventInfoDateDisplay(this.endDate, currentLang, true);
    }

    return '';
  }

  getAttendees(type: RSVPOptionType): EventAttendee[] | undefined {
    if (!this.attendees || this.attendees.length === 0) return undefined;

    if (type === RSVPOptionType.YES) {
      return this.attendees.filter(
        (attendee) =>
          attendee.rsvpType === type &&
          attendee.status === EventAttendeeStatus.ATTENDING,
      );
    } else if (type === RSVPOptionType.INVITED) {
      return this.attendees.filter(
        (attendee) => attendee.status === EventAttendeeStatus.INVITED,
      );
    } else if (type === RSVPOptionType.CHECKED) {
      return this.attendees.filter(
        (attendee) =>
          attendee.rsvpType === RSVPOptionType.YES &&
          attendee.status === EventAttendeeStatus.ATTENDING &&
          attendee.checkedIn,
      );
    }

    return this.attendees.filter((attendee) => attendee.rsvpType === type);
  }

  getRSVPSettingsButtonStyles() {
    if (
      this.eventTemplate?.rsvpSettings?.formAppearance ===
      RSVPFormAppearance.FILL
    ) {
      return {
        background: 'transparent',
        border: `1px solid ${
          this.eventTemplate?.rsvpSettings?.buttonBgColor ||
          this.eventTemplate?.font?.primaryLogoFontColor
        }`,
        color:
          this.eventTemplate?.rsvpSettings?.buttonTextColor ||
          Helpers.getTextColorForBg(
            this.eventTemplate?.font?.primaryLogoFontColor,
          ),
        'border-radius':
          this.eventTemplate?.rsvpSettings?.buttonBorderRadius + 'px',
      };
    } else {
      return {
        'background-color':
          this.eventTemplate?.rsvpSettings?.buttonBgColor ||
          this.eventTemplate?.font?.primaryLogoFontColor,
        color:
          this.eventTemplate?.rsvpSettings?.buttonTextColor ||
          Helpers.getTextColorForBg(
            this.eventTemplate?.font?.primaryLogoFontColor,
          ),
        'border-radius':
          this.eventTemplate?.rsvpSettings?.buttonBorderRadius + 'px',
      };
    }
  }
  getAttendeesByStatus(
    status: EventAttendeeStatus,
  ): EventAttendee[] | undefined {
    if (!this.attendees || this.attendees.length === 0) return undefined;

    return this.attendees.filter((attendee) => attendee.status === status);
  }

  getNumYesAttendees(): number {
    const yesAttendees = this.getAttendees(RSVPOptionType.YES);

    return yesAttendees ? yesAttendees.length : 0;
  }

  getNumInvitedAttendees(): number {
    const invitedAttendees = this.getAttendees(RSVPOptionType.INVITED);

    return invitedAttendees ? invitedAttendees.length : 0;
  }

  getNumNoAttendees(): number {
    const noAttendees = this.getAttendees(RSVPOptionType.NO);

    return noAttendees ? noAttendees.length : 0;
  }
  getNumGuests(): number {
    if (!this.attendees || this.attendees.length === 0) return 0;

    return this.attendees.filter((attendee) =>
      [
        EventAttendeeStatus.ATTENDING,
        EventAttendeeStatus.MAYBE_ATTENDING,
      ].includes(attendee.status),
    ).length;
  }

  getUserAttendee(userId: number): EventAttendee | undefined {
    return this.attendees?.find((attendee) => attendee.userId === userId);
  }

  getUserAttendeeRSVPOption(userId: number): EventRSVPOption | undefined {
    const attendee = this.attendees?.find(
      (attendee) => attendee.userId === userId,
    );
    const attendeeType = attendee?.rsvpType;

    return this.rsvpOptions?.find((option) => option.type === attendeeType);
  }

  getAttendeeRSVPOptionById(id: number): EventRSVPOption | undefined {
    const attendee = this.attendees?.find((attendee) => attendee.id === id);
    const attendeeType = attendee?.rsvpType;

    return this.rsvpOptions?.find((option) => option.type === attendeeType);
  }

  getAttendeeRSVPOptionByEmail(email: string): EventRSVPOption | undefined {
    const attendee = this.attendees?.find(
      (attendee) =>
        attendee.getEmail() &&
        Helpers.normalizeGmail(attendee.getEmail().toLowerCase()) ===
          Helpers.normalizeGmail(email.toLowerCase()),
    );
    const attendeeType = attendee?.rsvpType;

    return this.rsvpOptions?.find((option) => option.type === attendeeType);
  }

  getUserRSVPTitle(userId: number): string {
    if (
      this.getUserAttendeeRSVPOption(userId)?.name.trim() === 'Jetzt Anmelden'
    ) {
      return 'APP.EVENTS.REGISTERED';
    }

    return this.getUserAttendeeRSVPOption(userId)?.name || '';
  }

  getUserRSVPIcon(userId: number): string {
    const attendee = this.attendees?.find(
      (attendee) => attendee.userId === userId,
    );
    const attendeeType = attendee?.rsvpType;

    if (attendeeType === RSVPOptionType.YES) {
      return 'fa-check';
    }
    if (
      attendeeType === RSVPOptionType.NO ||
      attendeeType === RSVPOptionType.MAYBE
    ) {
      return 'fa-question';
    }

    return '';
  }

  isUserHost(userId?: number): boolean {
    return (
      this.hostUserId === userId ||
      !!this.hosts?.find(
        (host) =>
          host.userId === userId && host.status === EventHostStatus.ACTIVE,
      )
    );
  }

  isNotLoggedUserHost(): boolean {
    return this.uuid === localStorage.getItem('eventUuid');
  }

  isAttendee(userId?: number): boolean {
    return !!this.attendees?.find((attendee) => attendee.userId === userId);
  }

  isNonPendingAttendee(userId?: number): boolean {
    return !!this.attendees?.find(
      (attendee) => attendee.userId === userId && attendee.isNonPending(),
    );
  }

  isNonPendingAttendeeById(id?: number): boolean {
    return !!this.attendees?.find(
      (attendee) => attendee.id === id && attendee.isNonPending(),
    );
  }

  isNonPendingAttendeeByEmail(email?: string): boolean {
    if (!email) {
      return false;
    }
    return !!this.attendees?.find(
      (attendee) =>
        attendee.getEmail() &&
        Helpers.normalizeGmail(attendee.getEmail().toLowerCase()) ===
          Helpers.normalizeGmail(email.toLowerCase()) &&
        attendee.isNonPending(),
    );
  }

  isNonPendingActiveAttendee(userId?: number): boolean {
    return !!this.attendees?.find(
      (attendee) => attendee.userId === userId && attendee.isNonPendingActive(),
    );
  }

  isPendingAttendee(userId?: number): boolean {
    return !!this.attendees?.find(
      (attendee) =>
        attendee.userId === userId &&
        attendee.status === EventAttendeeStatus.PENDING_APPROVAL,
    );
  }

  isPendingAttendeeById(id?: number): boolean {
    return !!this.attendees?.find(
      (attendee) =>
        attendee.id === id &&
        attendee.status === EventAttendeeStatus.PENDING_APPROVAL,
    );
  }

  isPendingAttendeeByEmail(email?: string): boolean {
    if (!email) {
      return false;
    }
    return !!this.attendees?.find(
      (attendee) =>
        attendee.getEmail() &&
        Helpers.normalizeGmail(attendee.getEmail().toLowerCase()) ===
          Helpers.normalizeGmail(email.toLowerCase()) &&
        attendee.status === EventAttendeeStatus.PENDING_APPROVAL,
    );
  }

  getAttendee(userId?: number): EventAttendee | undefined {
    return this.attendees?.find((attendee) => attendee.userId === userId);
  }

  getAttendeeById(id?: number): EventAttendee | undefined {
    return this.attendees?.find((attendee) => attendee.id === id);
  }

  getAttendeeByEmail(email?: string): EventAttendee | undefined {
    if (!email) {
      return undefined;
    }
    return this.attendees?.find(
      (attendee) =>
        attendee.getEmail() &&
        Helpers.normalizeGmail(attendee.getEmail().toLowerCase()) ===
          Helpers.normalizeGmail(email.toLowerCase()),
    );
  }

  getEventGoingRSVPTitle(): string {
    const rsvpYes = this.rsvpOptions?.find(
      (option) => option.type === RSVPOptionType.YES,
    );
    if (rsvpYes) {
      if (rsvpYes.name.trim() === 'Jetzt Anmelden') {
        return 'APP.EVENTS.REGISTERED';
      }

      return rsvpYes?.name || '';
    }

    return '';
  }

  getEventRSVPTitle(type: RSVPOptionType): string {
    return this.rsvpOptions?.find((option) => option.type === type)?.name || '';
  }

  getYesRSVPOption(): EventRSVPOption | undefined {
    return this.rsvpOptions?.find(
      (option) => option.type === RSVPOptionType.YES,
    );
  }

  getNoRSVPOption(): EventRSVPOption | undefined {
    return this.rsvpOptions?.find(
      (option) => option.type === RSVPOptionType.NO,
    );
  }

  getEventDateInfo(): string {
    if (!this.endDate) {
      return moment(this.startDate).format('Do [of] MMMM · ha');
    }

    const startDate = moment(this.startDate).format('Do [of] MMMM');
    const startTime = moment(this.startDate).format('ha');
    const endTime = moment(this.endDate).format('ha');

    if (this.endsOnSameDate()) {
      return `${startDate} · ${startTime} - ${endTime}`;
    }

    return `${moment(this.startDate).format('Do [of] MMMM · ha')} - ${moment(
      this.endDate,
    ).format('Do [of] MMMM · ha')}`;
  }

  getEventDateInfoForCard(): string {
    if (!this.endDate) {
      if (!this.startDate) {
        return 'TBD';
      }
      return moment(this.startDate).format('Do [of] MMMM · ha');
    }

    const startDate = moment(this.startDate).format('Do [of] MMMM');
    const startTime = moment(this.startDate).format('ha');
    const endTime = moment(this.endDate).format('ha');

    if (this.endsOnSameDate()) {
      return `${startDate} · ${startTime} - ${endTime}`;
    }

    return moment(this.startDate).format('Do [of] MMMM · ha');
  }

  isInTheFuture(): boolean {
    return (
      this.isPublished() &&
      (!this.startDate || this.startDate.getTime() > Date.now())
    );
  }

  isInThePast(): boolean {
    return (
      this.isPublished() &&
      !!this.startDate &&
      this.startDate.getTime() < Date.now()
    );
  }

  isPublished(): boolean {
    return (
      this.status === EventStatus.ACTIVE ||
      this.status === EventStatus.ACTIVE_SPECIFIC_DATE
    );
  }

  isDraft(): boolean {
    return (
      this.status === EventStatus.DRAFT || this.status === EventStatus.INACTIVE
    );
  }

  getLink(): string {
    return this.type === EventType.LANDINGPAGE
      ? `${env.website}/landing/${this.uri}`
      : `${env.website}/events/${this.uri}`;
  }

  getVotingLink(): string {
    return `${env.website}/c/${this.eventCampaign?.campaign?.uri}/voting/${this.uri}`;
  }
  getVotingLinkRelative(): string {
    return `/c/${this.eventCampaign?.campaign?.uri}/voting/${this.uri}`;
  }

  getDisplayEndDateForCalendar(): string {
    return this.endDate
      ? this.endDate.toISOString()
      : moment(this.startDate).add(2, 'hours').toISOString();
  }

  getDisplayEndDateForCalendarIcs(): string {
    return this.endDate
      ? moment(this.endDate).utc().format('YYYYMMDDTHHmmss') + 'Z'
      : moment(this.startDate).add(2, 'hours').utc().format('YYYYMMDDTHHmmss') +
          'Z';
  }

  getEndDateForCalendar(): Date {
    return this.endDate
      ? this.endDate
      : moment(this.startDate).add(2, 'hours').toDate();
  }

  getStartDateTzMoment(): Moment {
    return moment.tz(this.startDate, this.timeZone);
  }

  getEndDateTzMoment(): Moment | null {
    return this.endDate ? moment.tz(this.endDate, this.timeZone) : null;
  }

  getStartDate() {
    const startDateTzMoment = this.getStartDateTzMoment();
    return startDateTzMoment
      ? new Date(
          startDateTzMoment.year(),
          startDateTzMoment.month(),
          startDateTzMoment.date(),
        )
      : null;
  }

  getEndDate(): Date | null {
    const endDateTzMoment = this.getEndDateTzMoment();
    return endDateTzMoment
      ? new Date(
          endDateTzMoment.year(),
          endDateTzMoment.month(),
          endDateTzMoment.date(),
        )
      : null;
  }

  getStartTime(): string {
    const startDateTzMoment = this.getStartDateTzMoment();

    if (!startDateTzMoment) {
      return '';
    }

    return this.is12HourDateFormat()
      ? startDateTzMoment.format('h:mm a')
      : startDateTzMoment.format('HH:mm');
  }

  getEndTime(): string {
    const endDateTzMoment = this.getEndDateTzMoment();

    if (!endDateTzMoment) {
      return '';
    }

    return this.is12HourDateFormat()
      ? endDateTzMoment.format('h:mm a')
      : endDateTzMoment.format('HH:mm');
  }

  getTime(): string {
    if (!this.startDate) {
      return '';
    }
    let dateInfo = this.getStartTime();

    if (this.endsOnSameDate()) {
      dateInfo += ` - ${this.getEndTime()}`;
    }

    return dateInfo;
  }

  getTimezoneOption(): TimezoneOption | undefined {
    if (!this.timeZone || this.timeZone === '') {
      return undefined;
    }
    return new TimezoneOption({
      name: this.timeZone,
      offset: moment.tz(this.timeZone).utcOffset(),
    });
  }

  hasCampaignLogo(): boolean {
    return (
      this.eventCampaign?.status === EventCampaignStatus.APPROVED &&
      !!this.eventCampaign?.campaign?.logoUrl &&
      this.eventCampaign?.campaign?.logoUrl !== ''
    );
  }

  getActiveCoHosts(): EventHost[] | undefined {
    return this.hosts?.filter(
      (x) =>
        x.status === EventHostStatus.ACTIVE &&
        x.role === EventHostRoleEnum.CO_HOST,
    );
  }

  maxCapacityReached(): boolean {
    return (
      this.maxCapacity !== undefined &&
      this.maxCapacity !== 0 &&
      this.maxCapacity <= (this.getNumYesAttendees() ?? 0)
    );
  }

  /*

  isRsvpWithoutEmail(): boolean {
    return this.rsvpWithoutEmail;
  } */

  getLandingPageEvents(): Event[] | undefined {
    return this.landingPageEvents
      ?.filter((landingPageEvent) => !!landingPageEvent.event)
      .map((landingPagEvent) => landingPagEvent.event!);
  }

  setDefaultRSVPOptions(): EventRSVPOption[] {
    const newRSVPOptions = [];
    if (
      !this.rsvpOptions?.find((option) => option.type === RSVPOptionType.YES)
    ) {
      newRSVPOptions.push(
        new EventRSVPOption({
          id: 0,
          eventId: this.id,
          type: RSVPOptionType.YES,
          name: 'APP.RSVP_OPTION.YES',
          emoji: '😀',
        }),
      );
    }
    if (
      !this.rsvpOptions?.find((option) => option.type === RSVPOptionType.NO)
    ) {
      newRSVPOptions.push(
        new EventRSVPOption({
          id: 0,
          eventId: this.id,
          type: RSVPOptionType.NO,
          name: 'APP.RSVP_OPTION.NO',
          emoji: '😢',
        }),
      );
    }
    if (
      !this.rsvpOptions?.find((option) => option.type === RSVPOptionType.MAYBE)
    ) {
      newRSVPOptions.push(
        new EventRSVPOption({
          id: 0,
          eventId: this.id,
          type: RSVPOptionType.MAYBE,
          name: 'APP.RSVP_OPTION.MAYBE',
          emoji: '🤔',
        }),
      );
    }

    return newRSVPOptions;
  }

  getEventCampaignStatus(): EventCampaignStatus | undefined {
    return this.eventCampaign?.status;
  }

  getEventCampaignPartyStatus(): EventCampaignPartyStatus | undefined {
    return (
      this.eventCampaign &&
      (this.eventCampaign?.partyStatus || this.campaignPartyStatus)
    );
  }

  getEventCampaignWorkingStatus(): EventCampaignWorkingStatus | undefined {
    return this.eventCampaign?.workingStatus;
  }

  getEventCampaignPartyStatusTableClass(): string | undefined {
    const partyStatus = this.getEventCampaignPartyStatus();

    if (!partyStatus) {
      return undefined;
    }

    switch (partyStatus) {
      case EventCampaignPartyStatus.DRAFT:
        return 'draft';
      case EventCampaignPartyStatus.UNQUALIFIED:
        return 'unqualified';
      case EventCampaignPartyStatus.QUALIFIED_MISSING_DATA:
        return 'qualified-missing-data';
      case EventCampaignPartyStatus.QUALIFIED_COMPLETE:
        return 'qualified-complete';
      case EventCampaignPartyStatus.NO_SUPPORT:
        return 'no-support';
      case EventCampaignPartyStatus.SUPPORTED:
        return 'supported';
      default:
        return '';
    }
  }

  getEventCampaignWorkingStatusTableClass(): string | undefined {
    const workingStatus = this.getEventCampaignWorkingStatus();

    if (!workingStatus) {
      return undefined;
    }

    switch (workingStatus) {
      case EventCampaignWorkingStatus.DECORATION_ORDERED:
        return 'deco-ordered';
      case EventCampaignWorkingStatus.POSTER_ORDERED:
        return 'poster-ordered';
      case EventCampaignWorkingStatus.DECORATION_AND_POSTER_ORDERED:
        return 'deco-and-poster-ordered';
      case EventCampaignWorkingStatus.PARTICIPATION_PACKAGE_SHIPPED:
        return 'package-shipped';
      default:
        return '';
    }
  }

  getEventCampaignCategory(): EventCampaignCategory | undefined {
    return this.eventCampaign?.category;
  }

  getEventCampaignInsuranceCheckLink(): string | undefined {
    return this.eventCampaign?.campaignInsuranceCheckLink?.link;
  }

  canRsvp(): boolean {
    if (!this.startDate) {
      return this.rsvpEnabled;
    }
    const rsvpDisableDate = this.rsvpDisableDate
      ? this.rsvpDisableDate
      : moment(this.startDate).add(2, 'hours').set({ minute: 0 });

    return this.rsvpEnabled && moment().isBefore(rsvpDisableDate);
  }

  getRsvpDisableDate(): Date | undefined {
    return this.rsvpDisableDate ? this.rsvpDisableDate : this.startDate;
  }

  getRsvpConfirmationTextByType(
    type: RSVPOptionType,
  ): EventRSVPConfirmationText | undefined {
    return this.rsvpConfirmationTexts?.find((text) => text.type === type);
  }

  isMainPictureVideo(): boolean {
    return !!this.mainPictureUrl?.includes('.mp4');
  }

  is12HourDateFormat(): boolean {
    return !!this.dateFormat && this.dateFormat === DateFormat.HOUR_12;
  }

  /*   getAttendeeQuestionsSorted(): EventAttendeeQuestion[] | undefined {
    return this.attendeeQuestions?.sort((a, b) => a.order - b.order);
  }

  getAttendeeQuestionsSortedForTable(): EventAttendeeQuestion[] | undefined {
    return this.attendeeQuestions?.sort((a, b) => b.order - a.order);
  } */

  getLowestTicketPriceDisplay(): string | undefined {
    if (!this.tickets || this.tickets.length === 0) {
      return undefined;
    }

    let lowestTicketPrice = 999999999;
    this.tickets?.forEach((ticket) => {
      if (ticket.price < lowestTicketPrice) {
        lowestTicketPrice = ticket.price;
      }
    });

    return `${NumberUtils.roundToUpTo2Decimals(lowestTicketPrice / 100.0)} €`;
  }

  getEncodedMainImage(allowVideo = false): string {
    let backgroundImage = '';
    if (!!this.mainPictureUrl && this.mainPictureUrl !== '') {
      if (allowVideo || !this.isMainPictureVideo()) {
        backgroundImage = this.mainPictureUrl;
      }
    } else if (
      this.eventTemplate?.visual?.keyVisual1?.url &&
      this.eventTemplate.visual?.keyVisual1?.url !== ''
    ) {
      backgroundImage = this.eventTemplate.visual.keyVisual1.url;
    }

    if (
      backgroundImage.includes('unsplash') ||
      backgroundImage.includes('giphy')
    ) {
      return encodeURI(backgroundImage);
    }

    return Helpers.encodeUrl(backgroundImage);
  }

  getSanitizedName(): string {
    return StringUtils.removeSpecialCharacters(this.name);
  }

  framesAvailable(): boolean {
    return this.type === EventType.WEDDINGPAGE;
  }

  private mapEventAttendees(attendees: EventAttendee[]): EventAttendee[] {
    return attendees.map((it) => new EventAttendee(it));
  }

  private mapEventRSVPOptions(
    rsvpOptions: EventRSVPOption[],
  ): EventRSVPOption[] {
    return rsvpOptions.map((it) => new EventRSVPOption(it));
  }

  private mapEventHosts(hosts: EventHost[]): EventHost[] {
    return hosts.map((it) => new EventHost(it));
  }

  private mapTickets(tickets: EventTicket[]): EventTicket[] {
    return tickets.map((it) => new EventTicket(it));
  }

  private mapAttendeeQuestions(
    attendeeQuestions: EventAttendeeQuestion[],
  ): EventAttendeeQuestion[] {
    return attendeeQuestions.map((it) => new EventAttendeeQuestion(it));
  }

  private mapRsvpConfirmationTexts(
    rsvpConfirmationTexts: EventRSVPConfirmationText[],
  ): EventRSVPConfirmationText[] {
    return rsvpConfirmationTexts.map((it) => new EventRSVPConfirmationText(it));
  }

  private mapLandingPageEvents(events: LandingPageEvent[]): LandingPageEvent[] {
    return events.map((it) => new LandingPageEvent(it));
  }
}

export function createExampleEventForTemplatePreview(
  eventTemplate: EventTemplate,
) {
  return new Event({
    id: 0,

    type: eventTemplate.type,

    uuid: '',
    uri: '',

    categoryId: 0,

    eventTemplateId: eventTemplate.id,
    eventTemplate: eventTemplate,

    hostUserId: 0,
    hostUser: new User({
      id: 0,

      email: 'test@eventpage.ai',
      emailVerified: true,

      role: UserRole.CUSTOMER,

      name: 'Erika Mustermann',

      phone: '',
      phoneVerified: false,

      avatarUrl:
        'https://images.pexels.com/photos/762020/pexels-photo-762020.jpeg',
      gender: '',
      description: '',
      language: '',
      platform: '',
      isB2B: false,
    }),

    eventAddressId: 0,
    eventAddress: eventTemplate.eventAddress
      ? eventTemplate.eventAddress
      : new Address({
          street: 'Musterstraße',
          streetNumber: '15',
          postalCode: '12345',
          locality: 'Musterstadt',
          lat: 49.4415725,
          lng: 8.2703097,
        }),

    attendees: [
      new EventAttendee({
        id: 1,
        userId: 0,
        user: new User({
          id: 0,

          email: 'test@eventpage.ai',
          emailVerified: true,

          role: UserRole.CUSTOMER,

          name: 'John Citizen',

          phone: '',
          phoneVerified: false,

          avatarUrl:
            'https://images.unsplash.com/photo-1560250097-0b93528c311a?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8cG9ydHJhaXQlMjBtYW58ZW58MHx8MHx8fDA%3D',
          gender: '',
          description: '',
          language: '',
          platform: '',
          isB2B: false,
        }),
        eventId: 0,
        status: EventAttendeeStatus.ATTENDING,
        rsvpType: RSVPOptionType.YES,
        rsvpDate: new Date(),
        numAttendees: 1,
        hasPaid: false,
      }),
      new EventAttendee({
        id: 1,
        userId: 0,
        user: new User({
          id: 0,

          email: 'test@eventpage.ai',
          emailVerified: true,

          role: UserRole.CUSTOMER,

          name: 'Max Schmidt',

          phone: '',
          phoneVerified: false,

          avatarUrl:
            'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0uUhhISOKkw5MpBnsCMWTiHZ9D8-bzGNsgw&usqp=CAU',
          gender: '',
          description: '',
          language: '',
          platform: '',
          isB2B: false,
        }),
        eventId: 0,
        status: EventAttendeeStatus.ATTENDING,
        rsvpType: RSVPOptionType.YES,
        rsvpDate: new Date(),
        numAttendees: 1,
        hasPaid: false,
      }),
      new EventAttendee({
        id: 1,
        userId: 0,
        user: new User({
          id: 0,

          email: 'test@eventpage.ai',
          emailVerified: true,

          role: UserRole.CUSTOMER,

          name: 'Marianna-sabine',

          phone: '',
          phoneVerified: false,

          avatarUrl:
            'https://images.unsplash.com/photo-1544005313-94ddf0286df2?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8ZmVtYWxlJTIwcG9ydHJhaXR8ZW58MHx8MHx8fDA%3D',
          gender: '',
          description: '',
          language: '',
          platform: '',
          isB2B: false,
        }),
        eventId: 0,
        status: EventAttendeeStatus.ATTENDING,
        rsvpType: RSVPOptionType.YES,
        rsvpDate: new Date(),
        numAttendees: 1,
        hasPaid: false,
      }),
      new EventAttendee({
        id: 1,
        userId: 0,
        user: new User({
          id: 0,

          email: 'test@eventpage.ai',
          emailVerified: true,

          role: UserRole.CUSTOMER,

          name: 'Lukas Müller',

          phone: '',
          phoneVerified: false,

          avatarUrl:
            'https://img.freepik.com/free-photo/young-bearded-man-with-striped-shirt_273609-5677.jpg?size=626&ext=jpg&ga=GA1.1.1700460183.1708041600&semt=ais',
          gender: '',
          description: '',
          language: '',
          platform: '',
          isB2B: false,
        }),
        eventId: 0,
        status: EventAttendeeStatus.ATTENDING,
        rsvpType: RSVPOptionType.YES,
        rsvpDate: new Date(),
        numAttendees: 1,
        hasPaid: false,
      }),
      new EventAttendee({
        id: 1,
        userId: 0,
        user: new User({
          id: 0,

          email: 'test@eventpage.ai',
          emailVerified: true,

          role: UserRole.CUSTOMER,

          name: 'Marie Dupont',

          phone: '',
          phoneVerified: false,

          avatarUrl:
            'https://img.freepik.com/free-photo/young-female-model-portrait_23-2149084889.jpg',
          gender: '',
          description: '',
          language: '',
          platform: '',
          isB2B: false,
        }),
        eventId: 0,
        status: EventAttendeeStatus.ATTENDING,
        rsvpType: RSVPOptionType.YES,
        rsvpDate: new Date(),
        numAttendees: 1,
        hasPaid: false,
      }),
      new EventAttendee({
        id: 1,
        userId: 0,
        user: new User({
          id: 0,

          email: 'test@eventpage.ai',
          emailVerified: true,

          role: UserRole.CUSTOMER,

          name: 'Alexander Wagner',

          phone: '',
          phoneVerified: false,

          avatarUrl:
            'https://t4.ftcdn.net/jpg/06/48/39/19/360_F_648391979_uMz6EwAlKNIJnK9r46UpTiM17nT8GuLl.jpg',
          gender: '',
          description: '',
          language: '',
          platform: '',
          isB2B: false,
        }),
        eventId: 0,
        status: EventAttendeeStatus.MAYBE_ATTENDING,
        rsvpType: RSVPOptionType.MAYBE,
        rsvpDate: new Date(),
        numAttendees: 1,
        hasPaid: false,
      }),
      new EventAttendee({
        id: 1,
        userId: 0,
        user: new User({
          id: 0,

          email: 'test@eventpage.ai',
          emailVerified: true,

          role: UserRole.CUSTOMER,

          name: 'Paul Hoffmann',

          phone: '',
          phoneVerified: false,

          avatarUrl:
            'https://t4.ftcdn.net/jpg/03/07/36/97/360_F_307369771_Mm1wbpgaNeaW36hLqivp86gnsx20LSWY.jpg',
          gender: '',
          description: '',
          language: '',
          platform: '',
          isB2B: false,
        }),
        eventId: 0,
        status: EventAttendeeStatus.MAYBE_ATTENDING,
        rsvpType: RSVPOptionType.MAYBE,
        rsvpDate: new Date(),
        numAttendees: 1,
        hasPaid: false,
      }),
    ],

    rsvpOptions: [
      {
        id: 0,
        eventId: 1,
        type: RSVPOptionType.YES,
        name: 'APP.RSVP_OPTION.YES',
        emoji: '😀',
      },
      {
        id: 0,
        eventId: 1,
        type: RSVPOptionType.NO,
        name: 'APP.RSVP_OPTION.NO',
        emoji: '😢',
      },
      {
        id: 0,
        eventId: 1,
        type: RSVPOptionType.MAYBE,
        name: 'APP.RSVP_OPTION.MAYBE',
        emoji: '🤔',
      },
    ],

    status: EventStatus.DRAFT,
    access: EventAccess.PUBLIC,

    name: eventTemplate.eventName,
    subHeader: eventTemplate.eventCta,
    description: eventTemplate.description,

    tags: '',

    frequency: EventFrequency.ONE_TIME,

    startDate: eventTemplate.startDate || moment().add(2, 'hours').toDate(),
    endDate: eventTemplate.endDate,
    timeZone: eventTemplate.timeZone || moment.tz.guess(),

    // plusOnesAdditionalData: false,
    /*    showPlusOnesFirstName: false,
    showPlusOnesLastName: false,
    showPlusOnesEmail: false,
    showPlusOnesPhone: false, */

    showGuestList: true,
    showGuestCount: true,
    showFeedTimestamps: false,
    canAttendeesAddPhotos: true,
    canAddPhotosViaHashtag: false,
    canAttendeeInviteMutuals: false,

    rsvpEnabled: true,
    requireAttendeeApproval: false,
    /*     requireGroupRsvp: false,
    rsvpWithoutEmail: false,
    oneButtonSignUp: false, */

    sendEmailNotifications: false,
    sendSmsNotifications: false,
    sendWhatsAppNotifications: false,

    isPro: false,

    isPreviewEvent: true,
  });
}
